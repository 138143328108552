.Header{
    margin-top: 10vh;
    color: whitesmoke;
    display: flex;
    flex-wrap: wrap;
    
}

.Header__left{
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
}

.Header__left h3{
    font-weight: 100;
  letter-spacing: 2px;
}

.Header__left h3 span{
    font-size: 3rem;
    font-weight:100;
}
.Header__left h1{
    justify-content: flex-start;
    font-size: 50px;
    font-weight: bold;
}

.Header__left h1 span{
    color: #e9755e;
}

.Header__left h2{
    font-size: 50px;
  font-weight: bold;
  color: #e9755e;
}

.Header__left h2 span{
    margin-left: 10px;
  color: whitesmoke;
}

.Header__right{
    flex: 1;
}


.containers
{
    margin-top: 15vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}
.btn
{
    width: 50px;
    height: 50px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2em;
    margin-right: 1em;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 10px;
}
i.fa
{
    font-size: 44px;
}
#fb
{
    color: #006fff;
    text-shadow: 0 0 30px #006fff;
}
#ig
{
    color: #ff5f40;
    text-shadow: 0 0 30px #ff5f40;
}
#tw
{
    color: #00acff;
    text-shadow: 0 0 30px #00acff;
}
#ut
{
    color: red;
    text-shadow: 0 0 30px red;
}
.btn.fb-btn
{
    box-shadow: 0 20px 20px -17px rgba(0,111,255,0.53);
}
.btn.fb-btn:hover
{
    transform: scale(1.2);
    box-shadow: 0 30px 45px -15px rgba(0,111,255,0.65);
}
.btn.ig-btn
{
    box-shadow: 0 20px 20px -17px rgba(255,16,39,0.5);
}
.btn.ig-btn:hover
{
    transform: scale(1.2);
    box-shadow: 0 30px 45px -15px rgba(255,16,39,0.57);
}
.btn.tw-btn
{
    box-shadow: 0 20px 20px -17px rgba(0,255,255,0.53);
}
.btn.tw-btn:hover
{
    transform: scale(1.2);
    box-shadow: 0 30px 45px -15px rgba(0,111,255,0.6);
}

.Header__right{

}

.brothers{
    width: 400px;
    height: 400px;
    border-radius: 50px;
}

@media (max-width:768px){
    .Header{
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
    }
    .Header__left{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .Header__right img{
        border-radius: 100%;
    }
    .containers{
        display: flex;
        justify-content: flex-start;
        align-items:flex-start;
    }
}