.C__container{
    margin-top: 8vh;
    color: whitesmoke;
    
}
.C__contact-form h2{
    margin-bottom: 5vh;
}
.C__contact{
    height: 100%;
    width: 100%;
    min-height: 100vh;
    display:flex;
    justify-content: center;
    align-items: center;
    grid-gap: 6rem;

}
.C__contact-form{
    width: 60%;
}

.C__contact-form h1{
    
    font-size: 50px;
    color: #fff;
    margin-bottom: 20px;
}

.C__contact-form h2 span{
    color:#e9755e;
    font-size: 80px;
}

.C__contact-form p{
    color: #c6c9d8bf;
    letter-spacing: 1px;
    line-height: 26px;
    font-size: 1.1rem;
    margin-bottom: 3.8rem;
}

.C__contact-form form{
    position: relative;
}

.C__contact-form form input, form textarea{
    width: 100%;
    padding: 17px;
    border: none;
    outline: none;
    background-color: #191919;
    color: #fff;
    font-size: 1.1rem;
    margin-bottom: 0.7rem;
    border-radius: 10px;

}
.C__contact-form form textarea{
    resize: none;
    height: 200px;
}

.C__contact-form form .cbtn{
    display: inline-block;
    background-color: #e9755e;
    font-size: 1.1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;
    border: 2px solid transparent;
    border-radius: 10px;
    width: 220px;
    transition: ease .20sec;
    cursor: pointer;
   
}

.C__contact-form form .cbtn:hover{
    border: 2px solid #e9755e;
    background:transparent;
    transform: scale(1.1);
}

.form__elements{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


