.T__Cards{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;

}
 
.wrapper,
.wrapper .img-area,
.social-icons a,
.buttons button{
background-color: rgba(255,255,255,0.13);
border-radius: 10px;
backdrop-filter: blur(10px);
border: 2px solid rgba(255,255,255,0.1);
box-shadow: 0 0 40px rgba(8,7,16,0.6);
}

.wrapper{
margin-top: 5vh;
position: relative;
width: 250px;
padding: 30px;
border-radius: 10px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}                                      

.wrapper .img-area{                    
height: 100px;
width: 100px;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
}
.img-area .inner-area{
height: calc(100% - 25px);
width: calc(100% - 25px);
border-radius: 50%;
}
.inner-area img{
height: 100%;
width: 100%;
border-radius: 50%;
object-fit: cover;
}
.wrapper .name{                        
font-size: 23px;
font-weight: 500;
color: white;
margin: 10px 0 5px 0;
}
.wrapper .about{
color: #d0d1d7;
font-weight: 400;
font-size: 16px;
}
.wrapper .social-icons{
margin: 15px 0 25px 0;
}
.social-icons a{
position: relative;
height: 40px;
width: 40px;
margin: 0 5px;
display: inline-flex;
text-decoration: none;
border-radius: 50%;
}                                      
.social-icons a:hover::before,
.wrapper .icon:hover::before,
.buttons button:hover:before{
content: "";
position: absolute;
top: 0;
left: 0;
bottom: 0;
right: 0;
border-radius: 50%;
background: #ecf0f3;
box-shadow: inset -3px -3px 7px #ffffff,
          inset 3px 3px 5px #ceced1;
}
.buttons button:hover:before{
z-index: -1;
border-radius: 5px;
}

.buttons button:hover{
  color: black;
}                                      
.social-icons a i{
position: relative;
z-index: 3;
text-align: center;
width: 100%;
height: 100%;
font-size: 20px;
line-height: 35px;
}
.social-icons a:nth-last-child(1) i{
color: #13d151;
}
.social-icons a:nth-last-child(2) i{
color: #f23400;
}
.social-icons a:nth-last-child(3) i{
color: #1da9e9;
}
.social-icons a:nth-last-child(4) i{
color: #e6ba12;
}

.wrapper .buttons{
display: flex;
width: 100%;
justify-content: space-between;
}
.buttons button{
position: relative;
width: 100%;
border: none;
outline: none;
padding: 12px 0;
color: #d0d1d6;                                                              
font-size: 17px;
font-weight: 400;
border-radius: 5px;
cursor: pointer;
z-index: 4;
}
.buttons button:first-child{
margin-right: 10px;
}
.buttons button:last-child{
margin-left: 10px;
}