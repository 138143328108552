.grid{
    margin-top: 0%;
    cursor: pointer;
    
}

.container{
    display: flex;
    gap: 3rem;
    justify-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    
  }
  
  .container .card{
    position: relative;
  }
  
  .container .card .face{
    width:300px;
    height: 200px;
    transition:.4s;
    
  }
  
  .container .card .face.face1{
    position: relative;
    background: #333;
    display: flex;
    justify-content: center;
    align-content:center;
    align-items: center;
    z-index: 1;
    transform: translateY(100px);
  }
  
  .container .card:hover .face.face1{
    transform: translateY(0);
    box-shadow:
      inset 0 0 10px whitesmoke,
      inset 5px 0 20px #f0f,
      inset -5px 0 20px #0ff,
      inset 5px 0 100px #f0f,
      inset -5px 0 100px #0ff,
      0 0 25px #fff,
      -5px 0 20px #f0f,
      5px 0 20px #0ff;
     
  }
  
  
  .container .card .face.face1 .content{
    opacity: .2;
    transition:  0.5s;
    text-align: center;
    
     
    
    
   
  
  }
  
  .container .card:hover .face.face1 .content{
    opacity: 1;
   
  }
  
  .container .card .face.face1 .content i{
    font-size: 3em;
    color: white;
    display: inline-block;
     
  }
  
  .container .card .face.face1 .content h3{
    font-size: 1em;
    color: white;
    text-align: center;
    
  
  }
  
  .container .card .face.face1 .content a{
     transition: .5s;
  }
  
  .container .card .face.face2{
     position: relative;
     background: whitesmoke;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 5px;
    box-sizing: border-box;
    box-shadow: 0 5px 5px rgba(0,0,0,.8);
    transform: translateY(-100px);
  }
  
  .container .card:hover .face.face2{
      transform: translateY(0);
  
  
  }
  
  .container .card .face.face2 .content p, a{
    font-size: 10pt;
    margin: 0 ;
    padding: 0;
    color:#333;
  }
  
  .container .card .face.face2 .content a{
    text-decoration:none;
    color: black;
    box-sizing: border-box;
    outline : 1px dashed #333;
    padding: 5px;
    margin: 15px 0 0;
    display: inline-block;
  }
  
  .container .card .face.face2 .content a:hover{
    background: #333 ;
    color: whitesmoke; 
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);
  }
  
  @media (max-width:768px){
    .container{
      margin: 0;
      padding: 0;
      gap: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }