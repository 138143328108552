.navebar{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navebar__left a{
    display: flex;
    align-items: center;
    color: whitesmoke;
	text-decoration: none;
    
}

.open {
	display: none;
}
.navebar__left a span:hover{
    cursor: pointer;
}
.alpha{
    font-size: 3rem;
}

.navebar__right{
    flex: 1;
    display: flex;
    justify-content:flex-end;
    gap: 1rem;
    align-items: center;
}

.navebar__right ul {
    display: flex;
    justify-content:space-evenly;
    gap: 2rem;
    align-items: center;
}
.navebar__right ul li{
    list-style: none;
}

.navebar__right ul li a{  
text-decoration: none;  
list-style: none;
color: whitesmoke;
cursor: pointer;
transition: 0.5s;
font-size: 13px;
letter-spacing: 2px;
}

.navebar__right ul li a:hover,
.navebar__right ul li a:focus{
    color: #e9755e;
}
.open {
	display: none;
}
.active{
    height: 12vh;
	position: fixed;
	top: 0;
	z-index: 1000;
	width: 92vw;
	background-color: black;
	box-shadow: -1px 11px 24px -11px rgba(0, 0, 0, 0.75);
	transition: 0.5s;
}
.toggle{
    background-color: black;
}
@media (max-width:768px){
    .navebar__right ul {
		padding-top: 30px;
	}
    
	.nav-links-mobile {
		position: absolute;
		display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
		list-style: none;
		font-weight: bold;
		letter-spacing: 5px;
        background-color: rgba(255,255,255,0.13);
backdrop-filter: blur(10px);
box-shadow: 0 0 40px rgba(8,7,16,0.6);
		left: 0;
		top: 0;
		transition: all 0.9s ease-in-out;
		width: 60%;
		height: 100vh;	
		z-index: 888;
        
	}
	.navebar__right ul li {
		width: 100%;
		transition: all 0.5s ease-in-out;
		text-transform: uppercase;
		line-height: 70px;
	}
	.navebar__right .link {
		display: none;
	}
	.close,
	.open {
		position: absolute;
        cursor: pointer;
		top: 20px;
		right: 20px;
		color: whitesmoke;
		display: block;
		font-size: 25px;
		z-index: 999;
	}
	.close {
		right: 45%;
	}
	.close.home-btn {
		background: rgba(255,255,255,0.13);
        cursor: pointer;
		border-radius: 50%;
		padding: 0;
		width: 60px;
		height: 60px;
		line-height: 60px;
	}
	.navebar__right.active {
		height: 10vh;
	}
}