.s__container{
    display: flex;
    flex-direction: column;
}

.s__title{
    font-size: 20px;
    color: whitesmoke;
}

.s__title h2 span {
    justify-content: flex-end;
    font-size: 80px;
    color:#e9755e ;
}
.s__card{
   display: flex;
   justify-content: center;
}