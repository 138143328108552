.P__Container{
margin-top: 0%;
}

.P__Container h2 {
    color: whitesmoke;
}

.P__Container h2 span{
    font-size: 80px;
    color:#e9755e 
}

.P__Container--Portfolio{
    
}